import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        secondary: {
            main: '#00934d'
        },
        warning: {
            main: '#e6e6e6'
        },
        info: {
            // main: "#3c7eb4"
            // main: '#006710'
            main: '#c05151'
        }
    },
    typography: {
        fontFamily: ['Roboto Flex', 'Arial'].join(',')
    },
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column'
                }
            }
        }
    }
});