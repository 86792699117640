import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {useAuth} from 'hooks/AuthProvider';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import {ROUTES} from "../../consts/routes";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import CastIcon from "@mui/icons-material/Cast";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";


const SidebarMenuMap = [
  ['Organizations Console', ROUTES.ORGANIZATIONS, <LocationCityIcon key={0} color="info" />, "org-side-button", ["wm", "manufacturer", "utility_tech"]],
  ['LoRa® Gateway Cloud Console', ROUTES.GATEWAYS, <SpaceDashboardIcon key={1} color="info" />, "gw-side-button", ["wm", "manufacturer"]],
  ['LoRa® Smart Readers Cloud Console', ROUTES.END_SMART_READERS, <CastIcon key={2} color="info" />, "sr-side-button", ["wm", "manufacturer"]],
  ['Devices pool', ROUTES.SMART_READER_POOL, <DeviceUnknownIcon key={3} color="info" />, "pool-side-button"],
];


export const Sidebar = () => {
    const {t} = useTranslation()
    const location = useLocation();
    const {currentUser} = useAuth();

    return (
        <List sx={{minWidth: 360}}>
            {SidebarMenuMap.map((endpoint, key) => {
                const [buttonText, endpointPath, buttonIcon, idText, userHideArray] = endpoint;

                if (userHideArray && userHideArray.length && userHideArray.includes(currentUser.role)) {
                    return <></>;
                }

                return (
                    <ListItem key={key} disablePadding>
                        <ListItemButton
                            id={idText}
                            component={Link}
                            to={endpointPath}
                            selected={location.pathname === endpointPath}
                        >
                            {buttonIcon}
                            <ListItemText
                                primary={t(buttonText)}
                                primaryTypographyProps={{
                                    fontFamily: 'Roboto Flex, sans-serif',
                                    fontWeight: 500,
                                    fontSize: 16,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: 1
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};