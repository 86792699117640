import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";


const SmartReaderApprovedStatus = ({approved}) => {
    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{timeout: 400}}
            title={approved ? "Approved" : "Pending Approval"}
            placement="top"
        >
            {approved ? <CheckCircleIcon style={{color: "green"}}/> : <ErrorIcon style={{color: "darkorange"}}/>}
        </Tooltip>
    )
}

export default SmartReaderApprovedStatus
