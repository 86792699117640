import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {API_URLS, QUERY_KEYS} from '../../../consts/consts';
import SmartReaderProvisionForm from './SmartReaderProvisionForm';
import {callApiV2} from '../../../services/server_apiV2';
import {notificationSuccess} from "../../../utils/utils";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddLinkIcon from "@mui/icons-material/AddLink";
import SmartReaderToGatewayLink from "../../GatewaysConsolePage/Forms/SmartReaderToGatewayLink";
import SmartReaderEditForm from "../../SmartReadersConsolePage/Forms/SmartReaderEditForm";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslation} from "react-i18next";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {deDE} from "@mui/x-data-grid/locales";
import {useAuth} from "../../../hooks/AuthProvider";


const UnlinkedDevicesTable = () => {
    const {t, i18n} = useTranslation();
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();

    const [displayDeviceProvisionPopUp, setDisplayDeviceProvisionPopUp] = useState(false);
    const [displayDeviceEditPopUp, setDisplayDeviceEditPopUp] = useState(false);
    const [displayLinkPopUp, setDisplayLinkPopUp] = useState(false);
    const [selectedSmartReader, setSelectedSmartReader] = useState(false);

    const {data: smartReadersObjects, isLoading: isDevicesLoading} = useQuery({
        queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED],
        queryFn: () => callApiV2({
            url: API_URLS.SMART_READERS,
            params: {is_linked_to_gw: 'False'}
        }).then((res) =>
            res.success ? res.data : Promise.reject(new Error(res.data))
        )
    });

    const handleRemoveSR = (eui) => {
        if (confirm(t(`Are you sure? (eui: {{eui}})`, {eui: eui}))) {
            callApiV2({url: `${API_URLS.SMART_READERS}${eui}/`, method: "DELETE"}).then(res => {
                if (res.success) {
                    notificationSuccess(t(`Smart Reader (eui: {{eui}}) successfully removed`, {eui: eui}))
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SMART_READERS_UNLINKED]});
                }
            })
        }
    }

    const rows = !smartReadersObjects ? [] : smartReadersObjects.map(
        (smartReader, id) => {
            return {
                id: smartReader.eui,
                join_eui: smartReader.join_eui,
                app_key: smartReader.app_key,
                nwk_key: smartReader.nwk_key,
                organization: smartReader.organization?.name,
                action: (
                    <>
                        {!["manufacturer"].includes(currentUser.role) && (
                            <Tooltip title={t("Link to Meter")}>
                                <IconButton
                                    id='link-sr-button'
                                    disabled={!smartReader.organization}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayLinkPopUp(true);
                                        setSelectedSmartReader(smartReader)
                                    }}
                                >
                                    <AddLinkIcon style={{color: smartReader.organization ? "green" : "grey"}}/>
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={t("Edit Smart Reader")}>
                            <IconButton
                                id='edit-sr-button'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayDeviceEditPopUp(true)
                                    setSelectedSmartReader(smartReader)
                                }}
                            >
                                <EditIcon style={{color: "orange"}}/>
                            </IconButton>
                        </Tooltip>
                        {!['utility_tech'].includes(currentUser.role) && (
                            <Tooltip title={t("Remove Smart Reader")}>
                                <IconButton
                                    id='remove-sr-button'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveSR(smartReader.eui)
                                    }}
                                >
                                    <DeleteIcon style={{color: "red"}}/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )
            };
        }
    )

    const headers = [
        {
            field: 'id',
            headerName: t('Smart Reader EUI'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'join_eui',
            headerName: t('Join EUI'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'app_key',
            headerName: t('App Key'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'nwk_key',
            headerName: t('Network Key'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'organization',
            headerName: t('Organization'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'action',
            headerName: t('Action'),
            width: 180,
            renderCell: (params) => {
                if (['helpdesk'].includes(currentUser.role)) {
                    return <></>
                }
                return params.value;
            },
            sortable: false,
        }
    ]


    return (
        <>
            {['superuser', 'wm', "manufacturer"].includes(currentUser.role) && (
                <Button
                    id='register-sr-button'
                    color="secondary"
                    variant="contained"
                    onClick={() => setDisplayDeviceProvisionPopUp(true)}
                    style={{margin: '1rem 0 1rem auto', display: 'block', textTransform: "capitalize"}}
                >
                    {t("Register Smart Reader")}
                </Button>
            )}

            <div style={{height: "70vh", width: '100%'}}>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    pageSizeOptions={[10, 25, 50]}

                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10}
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: searchParams ? [searchParams.get("search")] : [],
                            },
                        },
                    }}

                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: {disableToolbarButton: true},
                            csvOptions: {disableToolbarButton: true}
                        }
                    }}
                    slots={{toolbar: GridToolbar}}
                    loading={isDevicesLoading}
                    localeText={i18n.resolvedLanguage === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : undefined}

                    rows={rows}
                    columns={headers}
                />
            </div>

            <SmartReaderProvisionForm
                open={displayDeviceProvisionPopUp}
                openSetter={setDisplayDeviceProvisionPopUp}
            />

            {selectedSmartReader &&
                <SmartReaderEditForm
                    isOpen={displayDeviceEditPopUp}
                    setIsOpen={setDisplayDeviceEditPopUp}
                    smartReader={selectedSmartReader}
                />
            }
            {selectedSmartReader &&
                <SmartReaderToGatewayLink
                    open={displayLinkPopUp}
                    openSetter={setDisplayLinkPopUp}
                    smartReader={selectedSmartReader}
                />
            }
        </>
    )
}

export default UnlinkedDevicesTable
