import React, {useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {callApiV2} from '../../../services/server_apiV2';
import {API_URLS, QUERY_KEYS} from '../../../consts/consts';
import GatewayProvisionForm from './GatewayProvisionForm';
import GatewayEditForm from "../../GatewaysConsolePage/Forms/GatewayEditForm";

import AddLinkIcon from '@mui/icons-material/AddLink';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import {notificationSuccess} from "../../../utils/utils";
import LinkingGatewayForm from "./LinkingGatewayForm";
import {useTranslation} from "react-i18next";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useSearchParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {deDE} from "@mui/x-data-grid/locales";
import {useAuth} from "../../../hooks/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";


const UnlinkedGWTable = () => {
    const {t, i18n} = useTranslation();
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();
    const [isProvisionFormOpen, setIsProvisionFormOpen] = useState(false);
    const [isLinkFormOpen, setIsLinkFormOpen] = useState(false);

    const [displayGwEditPopUp, setDisplayGwEditPopUp] = useState(undefined);

    const {data: gatewaysObjects, isLoading: isGatewaysLoading} = useQuery({
        queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED],
        queryFn: async () =>
            await callApiV2({url: API_URLS.GATEWAYS, params: {is_linked: 'False'}}).then((res) =>
                res.success ? res.data : Promise.reject(new Error(res.data))
            )
    });

    const handleRemoveGW = (id) => {
        if (confirm(t(`Are you sure? (id: {{id}})`, {id: id}))) {
            callApiV2({url: `${API_URLS.GATEWAYS}${id}/`, method: "DELETE"}).then(res => {
                if (res.success) {
                    notificationSuccess(t(`Gateway (id: {{id}}) successfully removed`, {id: id}))
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GATEWAYS_UNLINKED]});
                }
            })
        }
    }

    const rows = !gatewaysObjects ? [] : gatewaysObjects.map((gateway, id) => (
        {
            id: gateway.id,
            name: gateway.name,
            organization: gateway.organization?.name,
            action: (
                <>
                    {!["manufacturer"].includes(currentUser.role) && (
                        <Tooltip title={t("Link Gateway")}>
                            <IconButton
                                id='link-gw-button'
                                disabled={!gateway.organization}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsLinkFormOpen(gateway)
                                }}
                            >
                                <AddLinkIcon style={{color: gateway.organization ? "green" : "grey"}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={t("Edit Gateway")}>
                            <IconButton
                                id='edit-gw-button'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayGwEditPopUp(gateway);
                                }}
                            >
                                <EditIcon style={{color: "orange"}}/>
                            </IconButton>
                        </Tooltip>
                    {!['utility_tech'].includes(currentUser.role) && (
                        <Tooltip title={t("Remove Gateway")}>
                            <IconButton
                                id='remove-gw-button'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveGW(gateway.id)
                                }}
                            >
                                <DeleteIcon style={{color: "red"}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            )
        }))

    const headers = [
        {
            field: 'id',
            headerName: t('Gateway ID'),
            flex: 12,
            sortable: false,
        },
        {
            field: 'organization',
            headerName: t('Organization'),
            flex: 12,
            sortable: false,
        },
        {
            field: 'action',
            headerName: t('Action'),
            renderCell: (params) => {
                if (['helpdesk'].includes(currentUser.role)) {
                    return <></>
                }
                return params.value;
            },
            width: 180,
            sortable: false,
        }
    ]

    return (
        <>
            {['superuser', 'wm', "manufacturer"].includes(currentUser.role) && (
                <Button
                    id='register-gw-button'
                    color="secondary"
                    variant="contained"
                    onClick={() => setIsProvisionFormOpen(true)}
                    style={{margin: '1rem 0 1rem auto', display: 'block', textTransform: "capitalize"}}
                >
                    {t("Register Gateway")}
                </Button>
            )}

            <div style={{height: "70vh", width: '100%' }}>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    pageSizeOptions={[10, 25, 50]}

                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10}
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: searchParams ? [searchParams.get("search")] : [],
                            },
                        },
                    }}

                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: {disableToolbarButton: true},
                            csvOptions: {disableToolbarButton: true}
                        }
                    }}
                    slots={{toolbar: GridToolbar}}
                    loading={isGatewaysLoading}
                    localeText={i18n.resolvedLanguage === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : undefined}

                    rows={rows}
                    columns={headers}
                />
            </div>

            <GatewayProvisionForm
                open={isProvisionFormOpen}
                openSetter={setIsProvisionFormOpen}
            />

            {Boolean(displayGwEditPopUp) &&
                <GatewayEditForm
                    isOpen={Boolean(displayGwEditPopUp)}
                    setIsOpen={() => setDisplayGwEditPopUp(undefined)}
                    gateway={displayGwEditPopUp}
                />
            }

            <LinkingGatewayForm
                open={Boolean(isLinkFormOpen)}
                openSetter={setIsLinkFormOpen}
                gateway={isLinkFormOpen}
            />
        </>
    )
}

export default UnlinkedGWTable
